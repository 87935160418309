@import url("https://fonts.googleapis.com/css2?family=Lexend+Deca&display=swap");
@import "./assets/css/variables";
body,
.MuiTypography-body1,
.MuiOutlinedInput-input,
.MuiInputBase-input {
    padding: 0;
    font-family: 'Lexend Deca', sans-serif !important;
    color: $text-color !important;
}

#root {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#dashboard-main-div {
    flex: 1;
}

.MuiTableCell-root {
    font-family: 'Lexend Deca', sans-serif !important;
    border-bottom: 0 !important;
}

.MuiIconButton-colorSecondary:hover {
    background-color: transparent !important;
}

.MuiTableCell-body {
    color: $text-color !important;
}

#repairemer-main-div+.error-heading #messageText {
    padding-top: 0 !important;
}

.overflow-auto {
    overflow: auto;
}

// Peabody Banner
.heading-Peabody {
    border-bottom: 0.625rem solid $yellow-color;
    background-color: $teal-color;
    padding: 1rem 1rem 2rem 1rem;
}

.heading-Peabody:before {
    content: "";
    position: absolute;
    background-color: transparent;
    right: 0;
    top: 0;
    width: 3.813rem;
}

//360
.repair-btn {
    .col {
        flex: 0 0 auto;
        padding-right: 0;
        padding-left: 0;
    }
    .col.text-end {
        padding-top: 0.75rem !important;
    }
    .secondary-ghost-btn {
        width: 100%;
    }
}

.option-width {
    width: 328px !important;
}

.body-card {
    background-color: $lightgrey-color;
    padding: 1.5rem;
    display: flex !important;
    flex-direction: column;
}

.border-terracotta {
    border-right: 0.5rem solid $terracotta-color;
}

.border-blue {
    border-right: 0.5rem solid $blue-color2;
}

.border-green {
    border-right: 0.5rem solid $green-color;
}

.border-yellow {
    border-right: 0.5rem solid $yellow-color;
}

.border-lightblue {
    border-right: 0.5rem solid $lightblue-color;
}

.border-teal {
    border-right: 0.5rem solid $teal-color;
}

.repair-divider:nth-of-type(2) {
    border-top: 1px solid $grey-color;
    margin-top: 1.5rem !important;
}

.btn-bottom {
    position: absolute;
    bottom: 1.5rem;
}

.display-column {
    display: flex !important;
    flex-direction: column;
}

.border-blue .record-repair {
    display: flex;
}

.record-repair {
    display: block;
}

li {
    list-style: none;
}

.recent-msg {
    background: $white-color;
    margin-top: 1.25rem;
    padding: 0.625rem 1.25rem;
}

.padding-paragraph {
    padding-top: 1rem !important;
}

.padding-repair {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.search-addressdd {
    width: 100%;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.confirm-bg-grey {
    background: $lightgrey-color;
}

.input-style {
    border: 2px solid $grey-color !important;
    height: 4.5rem;
    background-color: $white-color !important;
    font-size: 1.125rem !important;
    font-weight: 500 !important;
    color: $text-color;
    border-radius: 0 !important;
}

.text-input {
    width: 100% !important;
    padding: 1.25rem 1.5rem !important;
    @extend .input-style;
}

.text-select {
    background: url(./assets/img/arrow_downward_alt.png) no-repeat right;
    background-position-x: 97%;
    padding: 1.25rem 3.5rem 1.313rem 1.25rem !important;
    appearance: none;
    @extend .input-style;
}

.text-tenancy {
    font-size: 1.125rem !important;
    font-weight: 700;
}

.tenancy-padding {
    margin-top: 1.875rem;
    padding: 1.25rem 3.2rem 1.5rem 0 !important;
}

.input-text-select {
    background: url(./assets/img/arrow_downward_alt.png) no-repeat right;
    background-position-x: 97%;
    @extend .input-style;
}

.MuiSvgIcon-root {
    height: 1.875rem !important;
    width: 1.875rem !important;
}

.iframe-width {
    width: 60%;
}

.gen-error {
    margin-top: 15%;
    margin-left: 2%;
}

.height-44 {
    height: 44px !important;
}

.repair-card-btns {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding-right: 15px
}

.footer-actionbtn {
    .col,
    .col-md-3,
    .col-md-9 {
        margin-top: 1rem;
        margin-bottom: 1.5rem;
        padding-left: 0;
        flex: 0 0 auto;
        padding-top: 1rem !important;
    }
    .secondary-ghost-btn {
        width: 100%;
    }
    .secondary-ghost-btn.ml-16 {
        margin-left: 0;
        margin-top: 1rem;
    }
    .primary-btn {
        width: 100%;
    }
    .btn-top {
        border-top: 2px solid rgba(102, 102, 102, 0.50);
        padding-top: 1rem;
        padding-bottom: 0 !important;
    }
    .text-end {
        padding-top: 0 !important;
    }
}

.MuiContainer-maxWidthLg {
    max-width: 100% !important;
}

.parent-footer {
    min-height: 85vh;
    display: flex !important;
    flex-direction: column;
}

.parent-div {
    width: 100% !important;
    height: 21.375rem !important;
    flex-shrink: 0 !important;
    border-bottom: 10px solid $teal-color;
    background: $lightgrey-color;
    padding: 1.5rem 1.5rem 2.125rem 1.5rem;
    margin-top: 6px;
}

.MuiRadio-colorSecondary.Mui-checked {
    color: $text-color !important;
}

.MuiRadio-root {
    color: $text-color !important;
}

.MuiRadio-colorSecondary.Mui-checked:hover {
    background-color: transparent !important;
}

.MuiRadio-root {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.MuiFormGroup-root .MuiFormControlLabel-root {
    margin-bottom: 1.438rem !important;
}

.SpinnerWrapper {
    text-align: center;
}

//Vulnerability
.vulner-textarea {
    border-bottom: 0.625rem solid $teal-color;
    background: $grey-color2;
    padding: 1.5rem 1rem;
    .text-area {
        width: 100% !important;
        height: 13.563rem !important;
        padding: 8px;
    }
    .pt-16 {
        padding-top: 0 !important;
    }
}

.MuiFormGroup-root {
    flex-wrap: nowrap !important;
}

.action-btn {
    .btn-top {
        margin-bottom: 0;
    }
    .text-end {
        margin-top: 12px;
    }
}

.modal-footer {
    border: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
    margin-top: 1rem;
    display: block !important;
}

.back-arrowbtn,
.MuiTouchRipple-root,
.payment-backbtn,
.hideQuestion {
    display: none !important;
}

.payment-bordernone .btn-top {
    border-top: 0 !important;
}

.back-arrow {
    position: absolute;
    top: 20px;
}

.payment-backarrow {
    position: absolute;
    top: 20px;
    left: 15px;
}

.exit-arrowbtn {
    position: absolute;
    top: 15px;
    right: 10px;
    cursor: pointer;
}

.case-footer {
    // .btn-top {
    //     flex: 0 !important;
    //     padding: 0 !important;
    // }
    .text-end .primary-btn {
        width: 100%;
    }
}

.case-txtbox .amount-input {
    background-color: $white-color;
    label {
        display: inline;
    }
    label .MuiFormControl-root {
        margin: 0;
    }
    label .MuiOutlinedInput-root {
        padding: 0 !important;
    }
}

.header-logo {
    text-align: center;
}

.additional-infomsg #messageText {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.case-error #messageText {
    padding-top: 0 !important;
}

.question-error #messageText {
    padding-bottom: 0 !important;
}

@media (max-width: 576px) {
    .iframe-width {
        width: 98%!important;
        padding-left: 2%;
        touch-action: none;
    }
}

@media only screen and (max-width: 600px) {
    #dashboard-main-div,
    .MuiContainer-root {
        padding-left: 0rem !important;
        padding-right: 0rem !important;
    }
}

@media (min-width: 600px) {
    .MuiContainer-root {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

@media (min-width: 768px) {
    .case-txtbox .amount-input {
        width: 56%;
    }
}

@media screen and (min-width: 1023px) {
    .record-repair {
        display: flex;
    }
    .heading-Peabody {
        position: relative;
        border-right: 7.688rem solid $green-color;
        padding: 1.875rem 5rem 2.5rem 5rem;
        border-bottom: 0;
    }
    .heading-Peabody:before {
        background-color: $yellow-color;
        height: 100%;
    }
}

@media screen and (min-width: 1025px) {
    .parent-div {
        padding: 1.5rem;
    }
    .footer-actionbtn {
        .primary-btn {
            width: auto;
        }
        .col,
        .col-md-3 {
            padding-top: 1.875rem !important;
            flex: 1 0;
            border-top: 1px solid $border-grey !important;
        }
        .secondary-ghost-btn {
            width: auto;
        }
        .secondary-ghost-btn.ml-16 {
            margin-left: 1rem;
            margin-top: 0;
        }
    }
    .repair-btn {
        .col {
            padding-top: 1.875rem;
            flex: auto;
        }
        .col.text-end {
            padding-top: 0.75rem !important;
        }
        .secondary-ghost-btn {
            width: 100%;
        }
    }
    .padding-paragraph {
        padding-top: 0.625rem !important;
    }
    .border-terracotta {
        border-right: 3.125rem solid $terracotta-color;
    }
    .border-blue {
        border-right: 3.125rem solid $blue-color2;
    }
    .border-green {
        border-right: 3.125rem solid $green-color;
    }
    .border-yellow {
        border-right: 3.125rem solid $yellow-color;
    }
    .border-lightblue {
        border-right: 3.125rem solid $lightblue-color;
    }
    .border-teal {
        border-right: 3.125rem solid $teal-color;
    }
    .search-addressdd {
        padding-top: 2.5rem;
        padding-bottom: 1.875rem;
    }
    .heading-Peabody {
        h1 {
            padding-bottom: 1.25rem;
        }
    }
}

@media screen and (min-width: 1200px) {
    .payment-bordernone .btn-top {
        border-top: 2px solid rgba(102, 102, 102, 0.5);
    }
    .header-logo {
        text-align: left;
    }
    .case-footer {
        .btn-top {
            flex: 1 0 !important;
            padding-left: 5rem !important;
        }
        .text-end .primary-btn {
            width: auto;
        }
    }
    .exit-arrowbtn {
        right: 5rem;
    }
    .back-arrowbtn,
    .payment-backbtn {
        display: inline-block !important;
    }
    .back-arrow,
    .payment-backarrow {
        display: none;
    }
    .repair-btn {
        .col {
            padding-top: 1.875rem;
            flex: 1 0;
        }
        .col.text-end {
            padding-top: 1.875rem !important;
        }
        .secondary-ghost-btn {
            width: auto;
        }
    }
    .padding-repair {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }
}

.footer-main {
    background: $text-color;
}

.hjEcXz {
    width: 0 !important;
}

@media (min-width: 314px) and (max-width: 768px) {
    .desk-view {
        display: none !important;
    }
}

@media (min-width: 769px) {
    .mob-view {
        display: none;
    }
}

.font-bolder {
    font-weight: bolder;
}