@import "../../assets/css/variables";


.txtBox-Input {
    width: 100%;
    height: 2.75rem !important;
    flex-shrink: 0;
    border: 2px solid $grey-color !important;
    background: #FFF !important;
    border-radius: 0rem !important;
    padding: 0 1rem;
}

.input-text-select {
    width: 100%;
    padding: 0 1rem;
    appearance: none;
}

.input-error {
    border: 2px solid $red-error !important;
}

@media screen and (min-width: 1024px) {
    .txtBox-Input, .input-text-select {
        width: 32rem !important;
    }
}
