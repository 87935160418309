@import "../../assets/css/variables";

.attach-btn {
    border: 2px solid $border-color;
    height: 44px;
    padding: 2px 19px;
    background-color: $white-color;
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
}

.upload-section {
    display: block;
    text-align: center;
    padding: 1rem;
}

.para-h5 {
    padding-bottom: 12px;
    display: none;
    font-size: 20px;
    font-weight: 700;
  }

  .upload-section {
    padding: 0;
    #label-file-upload, .secondary-ghost-btn {
      width: 100%;
    }
  }

  .bg-white {
    padding: 1rem 4rem !important;
  }

  .attachment-name {
    padding-left: 4rem !important;
  }

  .attachment-remove {
    padding-right: 4rem !important;
  }

  .error-section #messageText {
    padding-top: 0 !important;
  }

  .input-file-upload {
    display: none;
  }

  .error-section {
    display: block;
    height: auto;
    background-color: $white-color;  
    margin-bottom: 20px;
    padding-bottom: 2rem;
}

  .file-img {
    width: 32px;
    height: 32px;
  }

  .file-name {
    width: 90% !important;
    padding-left: 0 !important;
    padding-top: 12px !important;
    border: 0 !important;
    border-bottom: 0 !important;
    vertical-align: top !important;
    padding-bottom: 0 !important;
    p {
        display: inline;
        padding-top: 5px;
    }
  }

  .file-row {
    border-bottom: 0;
  }

  .file-name.text-end {
    text-align: right !important;
  }


@media screen and (min-width: 1024px) {
  .file-name {
    width: 50% !important;
  }
    .file-name.text-end {
        text-align: left !important;
      }
      .error-section #messageText {
        width: 100%;
        top: 0;
    }
}

.upload-overlay {
    /* Height & width depends on how you want to reveal the overlay (see JS below) */
    height: 100%;
    width: 100%;
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    background-color: rgb(0, 0, 0); /* Black fallback color */
    background-color: rgba(0, 0, 0, 0.1); /* Black w/opacity */
    overflow-x: hidden; /* Disable horizontal scroll */
    transition: 0.5s; /* 0.5 second transition effect to slide in or slide down the overlay (height or width, depending on reveal) */
  }

  @media screen and (min-width: 1200px) {
    .error-section #messageText {
      position: absolute;
      width: 100%;
      top: 0;
  }
    .error-section {
      padding-top: 91px;
      padding-bottom: 120px;
      position: relative;
    }
    .para-h5 {
      display: block;
    }
    .upload-section {
      #label-file-upload, .secondary-ghost-btn {
        width: auto;
      }
    }
    .upload-section {
      padding: 24px 32px;
      display: block;
      text-align: center;
      border-bottom: 10px solid $teal-color;
      background: $grey-color2;
  }
}