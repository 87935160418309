
.repairs-search-address1 {
    width: 100%;
}

.gap-4 {
    gap: 1rem;
}

.grid {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
    grid-gap: 10px;
}

.gap-4 {
    grid-gap: 1.5rem!important;
    gap: 1.5rem!important;
}

.grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
}

.items-center {
    align-items: center;
}

.justify-between {
    justify-content: space-between;
}

.flex {
    display: flex;
}

@media (min-width: 768px) {
    .md\:gap-8 {
        gap: 2rem
    }
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
}

@media (min-width: 769px) {
    .md\:gap-8 {
        grid-gap: 2rem;
        gap: 2rem;
    }
    .md\:grid-cols-2 {
        grid-template-columns: repeat(2, minmax(0, 1fr));
    }
    
}

@media screen and (min-width: 1024px) {
    .repairs-search-address1 {
        width: auto;
    }

    .border-terracotta .record-repair p, .border-yellow .record-repair p, .border-teal .record-repair p {
        text-align: right;
        padding-left: 1rem;
    }
}