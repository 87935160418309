.radioinput {
    /* width: 1.9rem !important; */
    height: 1.9rem !important;
}

.startPageBackground {
    background-image: url(../../assets/img/bg-start-page.png);
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    display: none;
    align-items: center;
    color: white;
    min-height: 94vh;
    /* width: 100vw; */
}

.startPageContent {
    min-height: 50vh;
}

.innerContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    font-family: AvantGardeGothicITCW01Bk, Myriad Pro, Myriad, Sans-serif;
    margin-left: 20px;
}

.pgDetails {
    width: 45rem;
    font-size: 1.25rem;
    font-weight: 300;
}

.textLinks {
    text-decoration: none;
    color: white;
    /* font-size: 1.1rem; */
    font-weight: 400;
    cursor: pointer;
    margin-top: 10px;
    text-decoration: underline;
}

.textLinks:hover {
    text-decoration: underline;
    color: white;
}

.textButton {
    cursor: pointer;
}

@media (min-width: 314px) and (max-width: 640px) {
    .pgDetails {
        width: 20rem;
        font-size: 1rem;
    }
    .textLinks {
        font-size: 1rem;
    }
}

@media (max-width: 280px) {
    .pgDetails {
        width: 16rem;
        font-size: 0.8rem;
    }
    .textLinks {
        font-size: 1rem;
    }
}

@media (min-width: 314px) and (max-width: 414px) {
    .startPageBackground {
        background-image: url(../../assets/img/bg-start-page.png);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        display: none;
        align-items: center;
        color: white;
        min-height: 94vh;
        background-position: 90% 10%;
    }
}

@media (min-width: 768px) and (max-width: 820px) {
    .startPageBackground {
        background-image: url(../../assets/img/bg-start-page.png);
        background-position: bottom;
        background-repeat: no-repeat;
        background-size: cover;
        display: none;
        align-items: center;
        color: white;
        min-height: 94vh;
        background-position: right;
    }
}