@import "_variables.scss";

h1, h2, h3, h4, h5, h6, ul {
    margin: 0 !important;
    padding: 0 !important;
}


h1 {
    font-size: 2.5rem !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal !important;
}

h3 {
    font-size: 1.75rem !important;
    @extend h1;
}

h4 {
    font-size: 1.5rem !important;
    @extend h1;
}

h5 {
    font-size: 1.25rem !important;
    @extend h1;
}

h6 {
    font-size: 1rem !important;
    @extend h1;
}

p, label {
    font-size: 1rem;
    font-style: normal;
    font-weight: 300;
    line-height: normal;
    margin-bottom: 0 !important;
}

.style-1 {
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px;
}

.pt-12 {
    padding-top: 0.75rem !important;
}

.pt-16 {
    padding-top: 1rem !important;
}

.pt-18 {
    padding-top: 1.125rem !important;
}

.pt-20 {
    padding-top: 1.25rem !important;
}

.pt-24 {
    padding-top: 1.5rem !important;
}

.pt-30 {
    padding-top: 1.875rem !important;
}

.pt-40 {
    padding-top: 2.5rem !important;
}

.pt-44 {
    padding-top: 2.75rem;
}

.pt-60 {
    padding-top: 5rem;
}

.pb-8 {
    padding-bottom: 8px !important;
}

.pb-12 {
    padding-bottom: 0.75rem !important;
}

.pb-16 {
    padding-bottom: 1rem !important;
}

.pb-20 {
    padding-bottom: 1.25rem !important;
}

.pb-24 {
    padding-bottom: 1.5rem !important;
}

.pb-30 {
    padding-bottom: 1.875rem !important;
}

.pl-8 {
    padding-left: 8px;
}

.pl-16 {
    padding-left: 1rem;
}

.ml-16 {
    margin-left: 1rem;
}

.mr-16 {
    margin-right: 1rem !important;
}

.mt-8 {
    margin-top: 0.5rem !important;
}

.mt-12 {
    margin-top: 0.75rem !important;
}

.mtb-16 {
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.mb-12 {
    margin-bottom: 12px !important;
}

.mb-24 {
    margin-bottom: 1.5rem;
}

.mb-30 {
    margin-bottom: 1.88rem;
}

.mt-24 {
    margin-top: 1.5rem !important;
}

.mt-68 {
    margin-top: 4.5rem !important;
}

.marginTop-auto {
    margin-top: auto !important;
}

.error-color {
    color: $red-error;
}

.link {
    font-size: 1rem;
    font-weight: 700;
    line-height: normal;
    text-decoration-line: underline;
    color: $blue-color;
}

.secondary-ghost-btn {
    display: inline-flex;
    width: auto;
    height: 44px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    border: 2px solid $border-color;
    color: $text-color;
    font-size: $fontsize16;
    font-style: normal;
    font-weight: $fontweightbold;
    line-height: normal;
    background-color: $white-color;
    text-decoration: none;
    cursor: pointer;
    padding: 0.625rem 1.875rem;
}

.secondary-ghost-btn:hover {
	background-color: $lightgrey-color;
    color: $text-color;
    cursor: pointer;
}

.secondary-ghost-btn:disabled {
	background: rgba(0, 0, 0, 0.00);
	color: rgba(0, 0, 0, 0.50);
	opacity: 0.5;
}

.secondary-ghost-btn:focus {
	background-color: $white-color;
	border: 3px dashed $border-color;
}

.primary-btn {
    width: auto;
    height: 44px;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    font-size: $fontsize16;
    font-style: normal;
    font-weight: $fontweightbold;
    line-height: normal;
    color: $text-color;
    background-color: $teal-color;
    text-decoration: none;
    border: 0;
	transition: background .3s;
    padding: 10px 20px;
}

.primary-btn:hover {
	background-color: $teal-hovercolor;
    color: $text-color;
    text-decoration: none;
	outline-width: 0;
}

.primary-btn:disabled {
    color: rgba(0, 0, 0, 0.25);
    background-color: $primary-disabled;
}

.primary-btn:focus {
	outline: 2px dashed $text-color;
	background: $teal-hovercolor;
    color: $text-color;
    text-decoration: none;
}

.cursor-pointer {
    cursor: pointer;
}

.color-black {
    color: $text-color;
}

.color-white {
    color: $white-color;
}

.bordertop-none {
    border-top: 0 !important;
}

.table-hover>tbody>tr:hover>* {
    cursor: pointer;
}