.MuiButtonBase-root {
    justify-content: unset!important;
}

[aria-label="Previous page"],
[aria-label="Next page"] {
    background-color: none!important;
    min-width: 2rem!important;
}

.MuiSvgIcon-root.hover\:underline:hover {
    fill: currentColor!important;
}

.Mui-disabled {pointer-events: unset!important; }

.MuiTablePagination-root {
    overflow: hidden!important;
}

.text-address{
    padding-left: 21px;
}