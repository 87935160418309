@import "../../assets/css/variables";

.logo {
    width: 160px;
    height: 37.991px;
}

.navbar {
    display: flex;
    justify-content: space-between;
    font-size: 1.125rem;
    font-style: normal;
    font-weight: 500;
    line-height: 2rem;
    align-items: normal !important;
    padding-left: 1rem;
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
    padding-right: 1rem;
    outline: 1px solid $border-grey;
  }
  
  .nav-links {
    display: flex;
    li {
        line-height: normal;
        padding: 1rem 2rem 1rem 2rem;
        a {
            color: $text-color;
            text-decoration: none;
            cursor: pointer;
        }
    }
  }

  
.menu {
    display: flex;
    z-index: 999;
    border-top: 1px solid #A8A8A8;
}

.menu li:hover {
    transition: 0.3s ease;
}

.nav-links input[type=checkbox] {
    display: none;
} 
  
.hamburger {
    font-size: 1.5rem;
    user-select: none;
}

.repair-header {
    border-bottom: 5px solid $teal-color;
    text-align: center;
    .col-4 {
        width: 100%;
    }
    .content-heading {
        padding-bottom: 1rem;
        font-size: 1.125rem;
        font-weight: 500;
    }
}

@media (max-width: 1022px) {
    .detail-header {
        width: 89%;
        text-align: center;
        display: inline !important;
    }
    .menu {
        padding: 0;
    }
    .nav-links li {
        border-bottom: 1px solid #F7F7F7 !important;
        text-align: left;
        padding: 1rem;
    }
}
  
  /* APPLYING MEDIA QUERIES */
@media (max-width: 1023px) {
   .menu {
      display:none;
      position: absolute;
      background-color:$white-color;
      right: 0;
      left: 0;
      text-align: left;
      padding: 0;
      margin-top: 3rem;
    }
  
    .menu li:hover {
      display: inline-block;
      transition: 0.3s ease;
    }
  
    .nav-links input[type=checkbox]:checked ~ .menu {
      display: block;
    }
}

@media screen and (min-width: 992px) {
    .repair-header .content-heading {
        text-align: center;
        padding-top: 1.688rem;
        padding-bottom: 1.375rem !important;
        font-size: 1.75rem;
        font-style: normal;
        font-weight: 700;
        line-height: 2.188rem; 
    }
}

@media screen and (min-width: 1023px) {
    .menu {
        border-top: 0;
    }
    .closemenu-icon {
        display: none;
    }
    li.active {
        border-bottom: 5px solid $teal-color;
    }

    .hamburger {
        display: none;
    }

    .nav-links li {
        padding: 1.875rem 0.7rem 2.5rem;
    }

    .navbar {
        padding-top: 0 !important;
        padding-bottom: 0 !important;
    }

    .logo {
        width: 219px;
        height: 52px;
    }
}

@media screen and (min-width: 1024px) {

    .repair-header {
        border-bottom: 1px solid $border-grey;
        text-align: left;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        .col-4 {
            width: 33.33333333%;
        }
    }

    .navbar {
        padding: 0 1rem;
    }
}

@media screen and (min-width: 1200px) {
    .navbar {
        padding: 0 5rem !important;
    }

    .repair-header {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }
}

@media screen and (min-width: 1300px) {
    
    .nav-links li {
        padding: 1.875rem 1.438rem 2.5rem 1.438rem;
    }
}

