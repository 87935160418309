.text-teal {
    --tw-text-opacity: 1!important;
    color: rgba(0, 155, 170, var(--tw-text-opacity))!important;
}

.text-teal a:link {
    text-decoration: underline!important;
}

.mt-10 {
    margin-top: 10px;
}

.mt-30 {
    margin-top: 30px;
}

.mr-5 {
    margin-right: 5px;
}

.balance-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.balance-header {
    flex:1;
}

.balance-buttons{
    display: flex;
    gap:10px;
}

@media (max-width: 600px) {
    .balance-container {
        flex-direction: column;
    }

    .search-addressdd #viewaccount-account {
        width: 100% !important;
    }

    .balance-buttons .primary-btn {
        width: 21.5rem !important;
    }

    .search-addressdd #viewaccount-address {
        width: 100% !important;
    }

    .balance-container {
        align-items: flex-start !important;
    }

}

@media (min-width: 769px) {
    .md\:grid-cols-5 {
        grid-template-columns: repeat(5, minmax(0, 1fr));
    }
    
}

.single-account {
    padding-top: 0rem !important;
    padding-bottom: 0rem !important;
}
