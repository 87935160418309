@import "../../assets/css/variables";

.footer-navigation{
    display: flex;
    flex-direction: row;
}

.footer-logoimage {
    width: 11.4125rem !important;
    height: 2.75rem !important;
}

.footer-logo-align {
    text-align: left !important;
    order: 1;
    padding-top: 1rem;
}

.footer-copyright {
    color: $white-color;
    order: 3;
    text-align: left;
    padding-top: 1rem;
}

.footer-navigation {
    display: flex;
    flex-wrap: wrap;
    padding-top: 1rem !important;
    a {
        text-decoration: none !important;
        color: $white-color !important;
        cursor: pointer;
        padding-right: 8px;
    }
}

.footer-main {
    .row{
        padding: 1.5rem 1.063rem;
    }
}

.list-footer {
    order: 2;
}

@media screen and (min-width: 768px) {

    .footer-navigation a {
        padding-right: 12px;
    }

    .footer-copyright {
        padding-top: 11px;
        padding-left: 0 !important;
        order: 1;
    }

    .list-footer {
        padding: 11px !important;
    }

    .footer-main .row {
        padding: 1rem;
        margin: 0;
    }

    .footer-logo-align {
        text-align: right !important;
        padding-top: 0 !important;
        padding-right: 0 !important;
        order: 3;
    }

    .footer-navigation {
        padding-top: 0 !important;
    }
}

@media screen and (min-width: 1051px) {
    .list-footer {
        padding: 11px 0 0 0 !important;
    }
    .footer-main .row {
        padding: 1rem;
    }
}

@media screen and (min-width: 1200px) {
    .footer-main .row {
        padding: 1rem 5rem;
    }
}
