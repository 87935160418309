.successBg{
    background-color: #d6edd4;
}

.fa{
    color: #4b8d6e !important;
}

.flexContainer{
    width: 90%;
}

.iconChecked {
    width: auto;
    margin-left: 1rem;
    justify-content: center;
    align-items: center;
    padding-top: 0px;
}

.container {
    width: 90%;
    text-align: left;
    padding-top: 5px;
}

@media screen and (max-width: 430px) {
    .close {
        position: absolute;
        top: 10px;
        right: 10px;
        cursor: pointer;
      }

}     
@media screen and (max-width: 1023px) {
    .iconChecked {
        width: auto;
        margin-left: 1rem;
        justify-content: center;
        align-items: center;
        padding-top: 10px;
    }

    .modalPopUp {
        padding: 1.5rem;
        top: 52%;
        display: block;
        #exitmodal-div1 {
            width: 100%;
        }
    }
    
}
