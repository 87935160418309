@import "../../assets/css/variables";

#statement-download {
  display: flex;
}
#statement-download .secondary-ghost-btn {
  width: 11rem !important;
}

#statement-icon {
  position: relative;
  left: -1rem !important;
}

@media only screen and (max-device-width: 480px) {
  #statement-download {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  #statement-download .secondary-ghost-btn {
    margin-top: 1rem !important;
  }
}

@media screen and (min-width: 1023px) {
  .accounts-grey {
    margin-top: 8px;
    padding: 1.25rem 1rem;
    width: 31.625rem;
    background: $grey-color2;
  }

  #statement-download .input-text-select {
    width: 18.25rem !important;
    height: 2.75rem !important;
  }

  #statement-download .secondary-ghost-btn {
    width: 10.375rem !important;
    height: 2.75rem !important;
  }

}
