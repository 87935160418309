$teal-color: #1CABB6;
$teal-color2: #1B9BAA;
$text-color: #000000;
$border-color: #000000;
$blue-color: #1551C1;
$blue-color2: #1451C1;
$lightblue-color: #8ECBFF;
$terracotta-color: #FF6953;
$yellow-color: #FCE600;
$green-color: #5CB653;
$grey-color: #A8A8A8;
$lightgrey-color: #F7F7F7;
$fontsize16: 1rem;
$fontweightbold: 700;
$white-color: #FFFFFF;
$red-error: #FF1F00;
$teal-hovercolor: #15818a;
$primary-disabled: rgba(28, 171, 182, 1);
$border-grey: #CCCCCC;
$border-grey2: #666666;
$success-msg:#5cb65340;
$error-msg:#fe190040;
$warning-msg:#fce60040;
$info-msg: #E3F2FF;
$active-card: #009BAA;
$grey-color2: #F2F2F2;
$grey-color3: #a0a0a0;
$grey-color4: #ebebeb;
