@import "../../assets/css/variables";

.property-card {
    background-color: $lightgrey-color;
    outline: 1px solid $border-grey;
    padding: 1.188rem 1rem;
    cursor: pointer;
    img {
        margin-right: 0.875rem;
        width: 24px;
        height: 24px;
    }
    h6 {
        padding-top: 0.813rem !important;
    }
    h6 + p {
        padding-top: 0.5rem;
    }
}

.active-property {
    -webkit-box-shadow:inset 0 0 0 5px $active-card;
    -moz-box-shadow:inset 0 0 0 5px $active-card;
    box-shadow:inset 0 0 0 5px $active-card;
    outline: 1px solid $active-card;
}

.box {
    padding-left: 0 !important;
    -webkit-box-orient:vertical;
}

#repair-asset-divs {    
    .col-sm-12 {
        padding: 19px 20px;
        margin-bottom: 1rem;
        margin-top: 1.5rem;
        margin-right: 1rem;
    }
}

@media screen and (min-width: 992px) {
    .property-card {
        width: 100% !important;
    }
}

@media screen and (min-width: 1024px) {
    .box {
        -webkit-box-orient:horizontal;
    }

    #repair-asset-divs {
        .col-sm-12 {
            margin-right: 2rem;
            width: 20.5rem;
            margin-left: 0;
            margin-top: 1.938rem;
        }
        .col-sm-12:first-child {
            margin-top: 1.938rem;
        }
    }
}

@media screen and (min-width: 1200px) {
    .property-card {
        width: 22% !important;
    }
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : landscape) {
    .property-card {
        width: 46% !important;
    }

    #repair-asset-divs .row {
        --bs-gutter-x: 0;
        --bs-gutter-y: 0;
    }
}

@media only screen 
and (min-device-width : 768px) 
and (max-device-width : 1024px) 
and (orientation : portrait) {
    .property-card {
        width: 46% !important;
    }

    #repair-asset-divs .row {
        --bs-gutter-x: 0;
        --bs-gutter-y: 0;
    }
}