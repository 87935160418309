.characterCount {
    padding-left: -1rem;
}

.editor-container {
    height: 15rem;
    padding: 1rem;
  }

.contentPosition {
    margin-left: -.5rem;
}  

.w-alert {
    width: 98% !important
}
