.css-1vgzwi7-MuiFormControl-root{
    margin: 7px !important;
}

#referral-checkbox {
    opacity: unset !important;
    height: 1.15rem;
    width: 1.15rem; 
    top: unset !important;
    margin-left: 11px !important;
}

.css-ahj2mt-MuiTypography-root {
    margin: 10px !important;
}

.css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.Mui-checked, .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root.MuiCheckbox-indeterminate, .css-zun73v.Mui-checked, .css-zun73v.MuiCheckbox-indeterminate{
    color: #009baa !important;
}

.MobileView {
    margin-top: 0px;
}


@media (max-width:414px) {
    .MobileView {
        margin-top: -15px;
    }
    
}

@media (max-width:820px) {
    .MobileView {
        margin-top: -15px;
    }
    
}
