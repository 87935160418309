/* * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.container>p {
    font-size: 32px;
}

.question {
    width: 100%;
    color: #a45248;
    font-weight: 600;
}

.question-btn {
    color: #5b5b5e;
}

.options {
    position: relative;
    font-family: AvantGardeGothicITCW02Dm, Myriad Pro, Myriad, Sans-serif;
    padding-left: 40px;
}

#options label {
    display: block;
    margin-bottom: 30px;
    font-size: 14px;
    font-family: AvantGardeGothicITCW02Dm, Myriad Pro, Myriad, Sans-serif;
    color: #000;
    cursor: pointer;
}

.options input {
    opacity: 0;
}

.checkmark {
    position: absolute;
    top: -1px;
    left: 0;
    height: 24px;
    width: 25px;
    background-color: #ffffff;
    border: 2px solid #000000;
    border-radius: 50%;
}

.options input:checked~.checkmark:after {
    display: block;
}

.options .checkmark:after {
    content: "";
    width: 15px;
    height: 16px;
    display: block;
    background: #000000;
    position: absolute;
    top: 50%;
    left: 50%;
    border-radius: 50%;
    transform: translate(-50%, -50%) scale(0);
    transition: 300ms ease-in-out 0s;
}

.options input[type="radio"]:checked~.checkmark {
    background: #ffffff;
    transition: 300ms ease-in-out 0s;
}

.options input[type="radio"]:checked~.checkmark:after {
    transform: translate(-50%, -50%) scale(1);
}

.btn_primary {
    color: #fff;
    background-color: #00a3ad !important;
    border-color: #00a3ad !important;
}

.wh_fnt {
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    font-family: AvantGardeGothicITCW02Dm, Myriad Pro, Myriad, Sans-serif;
}

.wh_h1 {
    color: #fff;
    font-size: 0.875rem;
    font-weight: 400;
    font-family: AvantGardeGothicITCW02Dm, Myriad Pro, Myriad, Sans-serif!important;
}

.footer_hr {
    border-top: 1px solid #fff;
    margin-top: 20px !important;
}

.btn_disabled {
    color: #fff;
    background-color: grey !important;
    border-color: grey !important;
}

.btn-secondary:hover {
    color: #242424;
    background-color: transparent;
    border-color: transparent !important;
}

.breadcrumb {
    padding-top: 5px0.2rem !important;
    font-size: 14px;
    font-family: AvantGardeGothicITCW02Dm, Myriad Pro, Myriad, Sans-serif;
}

.breadcrumb a {
    text-decoration: none !important;
}

.dropdown-item {
    font-family: AvantGardeGothicITCW02Dm, Myriad Pro, Myriad, Sans-serif;
}

.style {
    padding: 15px 10px 2px 10px;
    --tw-bg-opacity: 1;
    background-color: rgba(0, 155, 170, var(--tw-bg-opacity));
    color: white!important;
}

@media (max-width: 576px) {
    .question {
        width: 100%;
        word-spacing: 2px;
    }
} */

.contentDiv {
    min-height: 54vh;
}
@media (max-width: 820px)
{
    .contentDiv{
        min-height: auto;
    }
}