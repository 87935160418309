.otherEmail {
    font-size: large;
    width: 100%;
}

.otherEmail:focus {
    border: 7px solid rgb(5, 196, 239);
}

.dateCSS {
    width: 10.2rem;
}

.countDownContainer {
    display: flex;
    justify-content: center;
}

.countDown {
    color: rgb(58, 232, 139);
    font-size: 3rem;
    font-weight: bold;
    text-shadow: 1px 1px black;
}

.alertMessageContainer {
    padding-right: 1rem;
}





@media (min-width: 314px) and (max-width: 912px) {
    .accountDatesContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .dateCSS {
        width: 100%;
        height: 3rem;
    }

    .dateCSS::-webkit-input-placeholder {
        opacity: 100;
    }


    .purpleAlertMessage {
        /* width: 14rem; */
    }

    .alertMessageMainContainer {
        padding: 0;
        padding-top: 1rem;
    }

    .alertMessageContainer {
        padding-right: .5rem;
    }

    .alertMessage {
        font-size: .8rem;
    }

}

@media (max-width: 280px) {
    .accountDatesContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }

    .dateCSS {
        width: 100%;
        height: 3rem;
    }

    .dateCSS::-webkit-input-placeholder {
        opacity: 100;
    }

    .alertMessageMainContainer {
        padding: 0;
        padding-top: 1rem;
    }

    .alertMessageContainer {
        padding-right: .5rem;
    }

    .alertMessage {
        font-size: .8rem;
    }
}