#confirmation-div-table {
    padding-top: 1rem;
    padding-bottom: 4.688rem;
    .col-11 {
        width: 83.33333333%;
    }
    .col-11 h6 {
        padding-bottom: 8px !important;
    }
    .row {
        padding: 1rem;
    }
    .col-1 {
        width: 16.333333%;
        text-align: right;
    }
}

.confirm-span1, .confirm-span2{
    width: 100%;   
    float: left;
    word-break: break-word;
}

@media screen and (min-width: 1024px) {

    .confirm-span1{
        width: 36%;
        float: left;
        margin: 16px !important;
    }
    .confirm-span2{   
        width: 55%;
        float: left;
        margin: 16px !important;
    }

    #confirmation-div-table .col-1 span {
        margin: 16px 53px 0 16px !important;
        display: flex;
    }

    #confirmation-div-table {
        padding-top: 1rem;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
        .col-11 {
            padding: 0 !important;
        }
        .row {
            padding: 0;
            margin: 0;
        }
    }

    #confirmation-div-table .col-11 h6 {
        padding-bottom: 0 !important;
    }
}

@media screen and (min-width: 1200px) {
    #confirmation-div-table {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }
}