@import "../../assets/css/variables";

#custDetails-container {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
}

.detail-msg .pb-16 {
    padding-bottom: 0 !important;
}

@media screen and (min-width: 1200px) {
    #custDetails-container {
        padding-left: 5rem !important;
        padding-right: 5rem !important;
    }
}