@import "../../assets/css/variables";

#vanQues-div2 {
    width: 100% !important;
    border-bottom: 10px solid $teal-color;
    background: $lightgrey-color;
    padding: 1.5rem 1.5rem 2.125rem 1.5rem;
    margin-bottom: 1.88rem;
}

#vanQues-main-btn {
    margin-bottom: -20px;
}