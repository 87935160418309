@import "../../assets/css/variables";

.repair-request .MuiInputBase-input, .repair-request textarea {
    width: 100%;
    height: 10.688rem !important;
    border: 1px solid rgba(107, 114, 128, 0.50) !important;
    padding: 12px 8px !important;
    color: $text-color !important;
    line-height: 24px !important;
}

.additional-error #messageText {
    padding-top: 0 !important;
    padding-bottom: 1rem !important;
}

.additional-error + div #messageText {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}


.additional-error + .additional-infomsg {
    padding-top: 0 !important;
}

.repair-request .textarea {
    width: 100%;
}

.repair-request .MuiOutlinedInput-root {
    padding: 0 !important;
}

.repair-request ul {
    padding-left: 2.6rem !important;
    line-height: 40px;
    padding-top: 1.25rem !important;
    list-style: none;
    display: grid;
    li::before {
        content: "\2022";
        color: $teal-color2;
        font-weight: bold;
        display: inline-block;
        width: 8px;
        margin-right: 4px;
      }
}

.css-1wc848c-MuiFormHelperText-root {
    font-family: 'Lexend Deca', sans-serif !important;
    color: $text-color !important;
    font-weight: normal !important;
    font-size: 1rem !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin-top: 1rem !important;
    line-height: normal !important;
}

.additional-order1 {
    order: 2;
}

.additional-order2 {
    order: 1;
    padding-left: 0 !important;
    li {
        line-height: normal;
        padding-bottom: 1rem;
        display: flex;
    }
}

@media screen and (min-width: 992px) {
    .additional-order1 {
        order: 1;
    }
    .additional-order2 {
        order: 2;
        padding-left: 2.25rem !important;
    }
}

@media screen and (min-width: 1024px) {

    .repair-request .MuiInputBase-input, .repair-request textarea {
        width: 38.875rem !important;
        height: 8rem !important;
    }

    .additional-order1 {
        order: 1;
    }
    
    .additional-order2 {
        order: 2;
        padding-left: 2.25rem !important;
    }
}