.tooltip {
  margin-left: 3px;
}
.s {
  cursor: pointer;
}
.mrleft {
  margin-left: -10px;
}
.mpointer {
  cursor: pointer;
}
.new-icon-alingment {
  margin-top: -5px;
}
