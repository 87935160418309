@import "../../assets/css/variables";

.acnt-type {
    padding-top: 1.5rem;
    table {
        width: 100%;
    }
}
.form-payment {
    padding-top: 1.5rem;
    label span.MuiButtonBase-root {
        padding-left: 0;
        padding-right: 12px;
    }
}

.payment-error #messageText {
    padding-top: 0 !important;
    padding-bottom: 1rem !important;
    margin-top: 2.5rem;
}

.payment-heading {
    display: none;
}

.other-amt {
    padding-top: 8px;
}

.amt-grey {
    margin-top: 8px;
    padding: 1.25rem 1rem;
    border-bottom: 10px solid $teal-color;
    background: $grey-color2;
}

.amount-sign {
    border: 2px solid $grey-color;
    display: inline-block;
    padding: 10px 9px;
    border-right: 0;
    background-color: $white-color;
}

.amount-input {
    width: 100%;
    height: 44px;
    border: 2px solid $grey-color;
    padding: 8px;
}

@media screen and (min-width: 768px) {
    .acnt-type {
        padding-top: 0;
        table {
            width: 50%;
        }
    }
    .form-payment {
        padding-top: 2.75rem;
    }
    .other-amt {
        padding-top: 1rem;
    }
}

@media screen and (min-width: 1024px) {
    .payment-heading {
        display: block;
        padding-bottom: 1.875rem !important;
    }
    .amount-input {
        width: 48%;
    }
}

@media screen and (min-width: 1200px) {
    .amount-input {
        width: 48%;
    }
    .amt-grey {
        margin: 9px 5rem 0;
    }
    .payment-error #messageText {
        margin-top: 0;
    }
    
}