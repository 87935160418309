@import "../../assets/css/variables";

.editprofile-container {
    padding-top: 1rem;
}

.disabledElement {
    background-color: transparent;
    border: 0 !important;
    width: 100%;
    padding: 0 !important;
    color: $text-color;
}

.w-512 {
    width: 100%;
}

.textfield-padding input {
    border: 1px solid $grey-color;
    padding: 12px;
}

.label-inputpadding {
    padding-bottom: 1rem !important;
  } 

@media (min-width: 314px) and (max-width: 912px) {
    .elementsContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .elementLabel {
        font-weight: bold;
    }
}

@media (max-width: 280px) {
    .elementsContainer {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
    }
    .elementLabel {
        font-weight: bold;
    }
}


@media screen and (min-width: 768px) {
    .disabledElement, .w-512 {
        width: 512px;
    }
    .editprofile-container {
        padding-top: 1.875rem !important; 
      }
    .customer-padding {
        padding-top: 2.5rem;
      }   
      .textfield-padding {
        padding-bottom: 30px !important;
      }
  }

  @media screen and (min-width: 1023px) {
    .manageprofile-buttons {
        width: auto;
      }
}