@import "../../assets/css/variables";

#duprepair-container table {
    border-spacing: 50px;
    margin-bottom: 0 !important;
  }
  
#duprepair-table tr td {
    width: 28% !important;
    text-align: left;
    color: $text-color !important;
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 300 !important;
    padding: 9px !important;
    padding-left: 0 !important;
    border: 0 !important;
    vertical-align: top;
  }

  .none-div {
    padding-left: 1.2rem;
    padding-top: 9px;
    .MuiButtonBase-root {
      padding-right: 1.2rem;
    }
  }

  #duprepair-main-div {
    display: flex;
    background: $lightgrey-color !important;
  }

  #duprepair-table tr {
    border: 0 !important  ;
  }

  #duprepair-table .padding-cell {
    padding-left: 13px !important;
  }

  #duprepair-table .padding-cell2 {
    padding-left: 0.4rem !important;
  }

  #duprepair-tr2 {
    border-width: 0;
  }
  
  .table-header-width {
    width: 95.30% !important;
    text-align: center;
    padding: 0 20px !important;
    margin-left: 52px;
    color: $text-color !important;
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 700 !important;
    line-height: normal;
  }
  
  .table-header-width-td {
    width: 98% !important;
    font-size: 1rem !important;
    font-style: normal;
    font-weight: 300 !important;
    text-align: center;
    padding: 0 20px !important;
  }
  
  .parent {
    display: inline-flex;
  }
  
  .mrgright {
    margin-right: 20px;
  }
  
  .repair-question {
    font-size: 12px;
  }
  
  .supp-table {
    max-width: 300px;
    font-size: 0.9rem !important;
  }
  
  .table-spc {
    letter-spacing: unset !important;
  }
  
  .table-wid {
    max-width: 80rem !important;
  }

  .header-table {
    background: $lightgrey-color !important;
    border-bottom: 2px solid rgba(102, 102, 102, 0.50);
    #duprepair-table tr td {
      font-weight: 700 !important;
    }
  }

  .dup-table {
    padding: 9px;
  }

  .dup-table .MuiFormControlLabel-root {
    margin: 0;
    margin-bottom: 0 !important;
  }
  
  @media (min-width: 314px) and (max-width: 768px) {
    .table-wid {
      display: block !important;
    }

    #duprepair-main-div {
      flex-direction: column;
      margin-bottom: 1rem !important;
    }
    
    #duprepair-tr1-td1,  #duprepair-tr1-td2, #duprepair-tr1-td3, #duprepair-tr1-td4 {
      border: 0 !important;
    }
  }
  
  @media (max-width: 600px) {
    .mobile-tablex {
      display: block !important;
    }
    .deskt {
      display: none !important;
    }
  }
  
  @media (min-width: 600px) {
    .mobile-tablex {
      display: none !important;
    }
    .deskt {
      display: unset;
    }
  }
  
@media (min-width: 1024px) {
  .lg\:static {
    position: static;
  }
}

.w-full {
  width: 100%;
}

.table {
  display: table;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.border-collapse {
  border-collapse: collapse;
}

.border-grey {
  --tw-border-opacity: 1;
  border-color: rgba(242,242,242,var(--tw-border-opacity))!important;
}

@media (min-width: 314px) and (max-width: 767px) {
  .dup-desk-hide{
      display: none !important;
  }
}