@import "../../assets//css/variables";

.success-msg {
    height: auto;
    background-color: $success-msg !important;
    padding: 1rem 3.313rem 1rem !important;
    background-image: url(../../assets/img/Vector.png);
    background-repeat: no-repeat;
    background-position-y: center;
    background-position-x: 10px; 
}

#messageText + .search-addressdd {
    padding-top: 1rem;
}

.error-msg {
    background-color: $error-msg !important;
    background-image: url(../../assets/img/error.png);
    @extend .success-msg;
}

.warning-msg {
    background-color: $warning-msg !important;
    padding: 0.75rem 0.75rem 0.75rem 3.313rem !important;
    background-image: url(../../assets/img/warning.png);
    @extend .success-msg;
}

.info-msg {
    background-color: $info-msg !important;
    padding: 0.75rem 0.75rem 0.75rem 3.313rem !important;
    background-image: url(../../assets/img/notifications.png);
    @extend .success-msg;
    background-position-x: 14px; 
}