.editProfile {
    display: none;
  }
  
  .customer-padding {
    padding-top: 1.5rem;
  }

  .customer-padding {
    padding-top: 1.5rem;
  }

  .label-padding {
    padding-bottom: 8px !important;
  }

  .textfield-padding {
    padding-bottom: 12px !important;
  }

  .manageprofile-container {
    padding-top: 1rem; 
  }
  
.manageprofile-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
}

.manageprofile-header {
    flex:1;
}

.manageprofile-buttons {
    display: flex;
    gap:10px;
    flex-wrap: wrap;
    margin-top: 1.5rem;
    width: 100%;
}

  @media screen and (min-width: 768px) {
    .manageprofile-container {
        padding-top: 1.875rem !important; 
      }
    .customer-padding {
        padding-top: 2.5rem;
      } 
      .label-padding {
        padding-bottom: 1rem !important;
      }   
      .textfield-padding {
        padding-bottom: 30px !important;
      }
      .manageprofile-buttons {
        margin-top: 0 !important;
      }
  }

@media (min-width: 314px) and (max-width: 912px) {
    .manageprofile-buttons .secondary-ghost-btn {
        width: -webkit-fill-available;
    }
    
}

@media screen and (min-width: 1023px) {
    .manageprofile-buttons {
        width: auto;
      }
}
