@import "../../assets/css/variables";

.repair-dd,
.history-input {
    margin-top: 5.5rem;
    width: 100%;

}

.filter-section {
    padding: 1.875rem;

    .offcanvas-body {
        padding: 0;
        width: 100%;
    }

    .sorting-dd {
        width: 100%;
    }

    .primary-btn {
        width: 11.125rem;
    }

    .offcanvas-header {
        padding: 0;
        display: flow;

        span {
            float: right;
        }
    }
}

#searchLabel .search-input {
    width: 100%;
}

.appoint-btn {
    width: 100%;
    padding: 0 1rem;

    .primary-btn,
    .secondary-ghost-btn {
        width: 100%;
        margin-bottom: 12px;
    }
}

#searchLabel {
    .search-input {
        border: 2px solid $grey-color !important;
        font-size: 1rem;
        font-weight: 500;
        height: 2.75rem;
        color: $text-color;
        align-items: normal;
        font-family: 'Lexend Deca', sans-serif !important;
        padding: 9px 14px;
        border-radius: 0;
        margin-top: 8px;
        margin-bottom: 8px;
    }

    .MuiInputAdornment-root {
        display: none;
    }

    .MuiOutlinedInput-input {
        padding: 0;
        height: fit-content !important;
    }
}

.filter-dd {
    border: 2px solid $text-color;
    height: 2.75rem;
    font-size: 1rem;
    font-weight: 700;
    color: $text-color;
    text-decoration: none;
    width: 100%;
    padding: 0;
    background-color: transparent;

    &:hover {
        color: $text-color;
    }
}

.col-md-6.history-dd {
    width: 45%;
}

.repair-filtericon {
    padding-left: 2rem;
    padding-right: 0.9rem;
}

.mobile-filtericon {
    padding: 0.61rem;
    border: 2px solid $text-color;
    margin-left: 1rem;
}

.rephistory-heading {
    padding-top: 1rem !important;
    font-size: 20px;
    font-weight: 700;
}

.mobile-repaircreate-yellow {
    border-right: 8px solid $yellow-color;
    background: $lightgrey-color;
    padding: 20px 16px;
    margin-bottom: 1.5rem;
}

.mobile-repaircreate-red {
    border-right: 8px solid $red-error;
    @extend .mobile-repaircreate-yellow;
}

.mobile-repaircreate-green {
    border-right: 8px solid $green-color;
    @extend .mobile-repaircreate-yellow;
}

.raise-repair {
    position: absolute;
    top: 1.5rem;
    width: 100%;
}

.history-result {
    background-color: $lightgrey-color;

    td {
        padding: 1rem !important;
        font-weight: 300;
        border: 0;
    }

    th {
        padding: 1rem !important;
        font-weight: 700;
    }
}

.search-field {
    margin-top: 8px;

    .col-md-6 {
        width: 46%;
        margin-right: 5%;
    }

    .col-1 {
        width: 21%;
        padding: 0;
    }

    .col-2 {
        width: 13%;
        padding: 0;
        margin: 0 1%;
    }

    .col-3 {
        width: 12.7%;
        padding: 0;
    }
}

.sorting-dd {
    width: 82.6%;
    height: 44px;
    border: 2px solid $grey-color;
    padding: 8px 12px;
    font-size: 16px;
    font-weight: 500;
    background: url(../../assets/img/arrow_downward_alt.png) no-repeat right;
    background-position-x: 97%;
    appearance: none;
}

.status-yellow,
.status-green,
.status-red {
    border-right: 8px solid $yellow-color;
    background: $lightgrey-color;
    margin-bottom: 1.5rem;
    padding: 1.25rem 1.063rem;

    .row {
        border-bottom: 1px solid $border-grey;
        padding-bottom: 1rem !important;
    }

    h6 {
        padding-bottom: 8px !important;
        padding-top: 16px !important;
    }
}

.status-red {
    border-right: 8px solid $red-error;
    background: $lightgrey-color;
    margin-bottom: 1.5rem;
    padding: 1.25rem 1.063rem;

    h6 {
        padding-bottom: 8px !important;
        padding-top: 16px !important;
    }
}

.status-green {
    border-right: 8px solid $green-color;
    background: $lightgrey-color;
    margin-bottom: 1.5rem;
    padding: 1.25rem 1.063rem;

    h6 {
        padding-bottom: 8px !important;
        padding-top: 16px !important;
    }
}

.history-viewDetails {
    border: 2px solid $text-color;
    height: 44px;
    text-align: center;
    padding: 9px;
    margin-top: 1rem;
    font-size: 1rem;

    p {
        font-weight: 700;
    }
}

.repair-details {
    margin-top: 12px !important;

    h5 {
        border-bottom: 1px solid $border-grey;
        padding-bottom: 8px !important;
        margin-bottom: 8px !important;
    }

    .pb-6 {
        padding-bottom: 6px !important;
    }

    .pb-12 {
        padding-bottom: 12px !important;
    }
}

.large-leftarrow {
    display: none;
}

.small-leftarrow {
    padding-bottom: 4px;
    padding-right: 5px;
}

.mobile-view {
    padding: 0 !important;
}

.history-section {
    h5 {
        border-bottom: 1px solid $border-grey;
        padding-bottom: 16px !important;
        margin-bottom: 8px !important;
    }

    .pb-7 {
        padding-bottom: 7px !important;
    }
}

.repaircreate-date {
    text-align: left;
    padding-bottom: 20px;
    padding-top: 8px;
}

.status-style,
.status-style-yellow,
.status-style-green,
.status-style-red {
    float: right;
}

.media-img li {
    list-style: none;
    padding-bottom: 1.5rem;
    display: flex;

    span {
        padding-left: 1rem;
        padding-top: 12px;
        word-break: break-word;
        cursor: pointer;
    }

    &:first-child {
        padding-top: 1.5rem;
    }
}

.appoint-desktop {
    display: none;
}

.back-btn {
    cursor: pointer;
}

.width-35 {
    width: 35%;
}

.width-20 {
    width: 20%;
}

.h-88 {
    height: 88%;
}

.mediaViewOverlay {
    height: 28.125rem;
    width: 61%;
    left: 21%;
    top: 6%;
    position: relative;
    background-color: $white-color;
    padding: 1.5rem;
}

.MuiSelect-icon {
    top: 0 !important;
    right: -4px !important;
}

.bg-purple-20 {
    --tw-bg-opacity: 1;
    background-color: rgba(234, 215, 230, var(--tw-bg-opacity))
}

.text-purple-100 {
    --tw-text-opacity: 1;
    color: rgba(150, 54, 131, var(--tw-text-opacity))
}

.h-6 {
    height: 1.5rem
}

.w-6 {
    width: 1.5rem
}

.warning-pre-line {
    white-space: pre-line;
    margin-top: 1rem !important;
}

.mb-8 {
    margin-bottom: 2rem
}

.mt-local-1 {
    margin-top: 1rem
}

.alert {
    position: relative;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
}

.fw-bold {
    font-weight: bold;
}

.p-0 {
    padding: 0% !important;
}

.border-none {
    border-top: none !important;
    margin-top: 0rem !important;
}

@media screen and (min-width: 412px) {
    .sorting-dd {
        width: 85%;
    }
}

@media screen and (min-width: 430px) {
    .sorting-dd {
        width: 86%;
    }
}

@media screen and (min-width: 768px) {

    #cancelMsg,
    #onHoldRepairMsg {
        padding: 0 !important;
    }
    
    #warningMsg{
        padding: 0 !important;
    }

    .sorting-dd {
        width: 92%;
    }

    .descr-padding {
        padding-top: 1.5rem;
    }

    .appoint-section {
        background-color: $lightgrey-color;
        padding: 1.25rem 1.813rem !important;
    }
}

@media screen and (min-width: 920px) {
    .history-table {
        padding-right: 4px !important;
    }

    .histoy-desc {
        width: 31%;
    }

    .req-type {
        width: 27%;
    }

    .owner-case {
        width: 25%;
    }

    .status-case {
        width: 13%;
    }

    .col-md-6.history-dd {
        width: 97.5%;
    }
}

@media (max-width: 1022px) {
    .detail-backarrow {
        position: absolute;
        top: -47px;
    }
}

@media screen and (min-width: 1023px) {
    .history-section .media-section {
        padding-left: 0;
    }

    .descr-padding {
        padding-left: 0 !important;
    }

    .large-leftarrow,
    .appoint-desktop {
        display: inline;
    }

    .appoint-mobile,
    .small-leftarrow {
        display: none;
    }

    .appoint-btn .primary-btn,
    .appoint-btn .secondary-ghost-btn {
        width: auto;
        margin-bottom: 0;
        margin-left: 0.813rem;
    }

    .mobile-repaircreate-yellow,
    .mobile-repaircreate-red,
    .mobile-repaircreate-green {
        margin-top: 1.5rem;
        border-right: 0;
        background-color: transparent;
        padding: 0;
        display: flex;
        margin-bottom: 0;
        height: 3rem;

        h2 {
            padding-top: 5px !important;
            margin-right: 1.5rem !important;
        }
    }

    .mobile-view {
        padding: 0 1rem !important;
    }

    .status-style-yellow {
        border: 1px solid $yellow-color;
        background: rgba(252, 230, 0, 0.25);
        padding: 8px 10px 10px 10px;
        font-size: 1rem;
        font-weight: 700;
        float: none;
        margin-top: 6px;
    }

    .status-style-green {
        border: 1px solid $teal-color;
        background: rgba(28, 171, 182, 0.10);
        padding: 8px 10px 10px 10px;
        font-size: 1rem;
        font-weight: 700;
        float: none;
        margin-top: 6px;
    }

    .status-style-red {
        border: 1px solid $terracotta-color;
        background: rgba(255, 105, 83, 0.10);
        padding: 8px 10px 10px 10px;
        font-size: 1rem;
        font-weight: 700;
        float: none;
        margin-top: 6px;
    }

    .history-section {
        padding: 0 !important;
        margin-left: 5px !important;
    }

    .repaircreate-date {
        text-align: right;
    }

    .back-page {
        display: inline;
        border-right: 1px solid $border-grey;
        margin-right: 1rem;
    }

    .rephistory-heading {
        padding-top: 1.375rem !important;
        font-size: 2.25rem;
    }

    .appoint-btn {
        position: absolute;
        right: 1.313rem;
        width: auto;
        left: auto;
        top: auto;
        padding: 0;
    }

    .sorting-dd {
        width: 100%;
    }

    .raise-repair {
        right: 2rem;
        width: auto;
    }

    .repair-details {
        padding-top: 2rem !important;
        margin-top: 0 !important;
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }

    #searchLabel,
    #searchId {
        .search-input {
            margin-top: 0;
            margin-bottom: 0;
        }
    }

    .repair-dd,
    .history-input {
        margin-top: 1.875rem;
        padding: 1.25rem 3.2rem 1.313rem 1.25rem !important;
    }

    .search-field {
        margin-top: 1rem !important;
        margin-bottom: 1.875rem;
    }

    .history-result .table>:not(:first-child) {
        border-top: 1px solid $border-grey;
    }
}


@media screen and (min-width: 1200px) {

    .appoint-btn {
        right: 5.313rem;
    }

    .mobile-view {
        padding: 0 5rem !important;
    }

    .raise-repair {
        right: 6rem;
    }
}

@media screen and (min-width: 1400px) {
    .search-field .col-3 {
        width: 12.3%;
    }
}

@media screen and (min-width: 1600px) {
    .search-field .col-3 {
        width: 12.6%;
    }

    .col-md-6.history-dd {
        width: 98%;
    }
}