@import "../../../assets/css/variables";

.mob-message-history {
    .width-date {
        width: 12%;
    }
    .width-sender {
        width: 20%;
    }
    .width-subject {
        width: 20%;
    }
    .width-message {
        width: 58%;
    }
}

@media (min-width: 769px) {

    .mob-view {
        display: none;
    }

    .message-details-overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis !important;
        max-width: 43.75rem !important;
    }

    .message-subject-overflow {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis !important;
        max-width: 7.25rem !important;
    }  
    
    .msg-clear-filter-margin {
        margin-left: 0.62rem !important;
    }
    
    .msg-filter-cta {
        width: 8.875rem;
        height: 2.75rem;
    } 
    
    .msg-clear-filter-margin {
        margin-left: 0.62rem !important;
    }
    
    .msg-filter-cta {
        width: 8.875rem;
        height: 2.75rem;
    }
}

@media (min-width: 1024px) {
    .mob-calendar-view {
        float: left;
        margin-right: 1rem;
    }
    .search-msg {
        float: left;
        width: 27%;
    }
    .messages-filters-mob-view {
        float: right;
    }
    .messages-filter-reset {
        float: left;
        width: 100%;
    }
}

.message-read {
    font-weight: 300;
    line-height: normal;
}

.message-unread {
    font-weight: 700;
    line-height: normal;
}

@media only screen and (max-width: 768px) {
    #messageHistory .row {
        display: flex;
        flex-direction: column;
    }

    .col-md-4 {
        width: 100%;
        margin-bottom: 10px;
    }

    .mob-message-history {
        display: none;
    }

    .message-details-view {
        display: flex;
        flex-direction: column;
    }

    .mob-message-row {
        min-width: 22.5rem !important;
        min-height: 7.4375rem !important;
        border-bottom: 2px solid $grey-color;
    }

    .mob-message-container-border {
        border-top: 8px solid $teal-color;
    }

    .mob-calendar-view {
        display: flex;
        justify-content: space-between;
        flex-wrap: nowrap;
        flex-direction: row;
    }

    .calendar-min-height {
        width: 50%;
        height: 3.75rem !important;
        border: 2px solid $grey-color;
        color: $text-color;
        font-size: 1rem;
        font-weight: 500;
    }  

    .messages-filters-mob-view {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .msg-clear-filter-margin {
        margin-left: 0.75rem !important;
    }
    
    .msg-filter-cta {
        width: 9.9375rem;
        height: 2.75rem;
    }

    .filter-elements-margin-top {
        margin-top: 1rem !important;
    }

    #messageHistory .property-messages .repair-dd {
        margin-top: 1.5rem !important;
    }

    #messageHistory .property-messages .history-input {
        margin-top: 1.5rem !important;
    }
}

@media screen and (min-width: 1400px) {
    .message-details-overflow {
        max-width: 50.75rem !important;
    }
  }

  @media screen and (min-width: 1600px) {
    .message-details-overflow {
        max-width: 60.75rem !important;
    }
  }

  @media only screen 
  and (min-width: 1024px) 
  and (max-height: 1366px) 
  and (-webkit-min-device-pixel-ratio: 1.5) {
    .message-details-overflow {
        max-width: 36.75rem !important;
    }
}
 
@media screen and (min-width: 768px) and (max-width: 820px) {
    .mob-message-row {
        min-width: 45.5rem !important;
    }
}
