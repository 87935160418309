.message-detail-heading {
    margin-top: 1.94rem !important;
}

.detail-date-align {
    text-align: right;
}

.detail-sender-date-margin {
    margin-top: 2.5rem !important;
}

.detail-text-margin {
    margin-top: 1.5rem !important;
}

.message-details-header {
    font-weight: 700;
    line-height: normal;
}