@import "../../assets/css/variables";

.mediaHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0rem 2rem;
  background-color: rgb(242, 242, 242);
  height: 2.5rem;
  flex-grow: 1;
}

.mediaHeaderName {
  flex-basis: 60%;
}

.mediaRowContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 2rem;
  flex-grow: 1;
}

.progressBar {
  width: 25%;
}

.mediaName {
  flex-grow: 6;
}

.deleteButtonContainer {
  margin-left: 8rem;
  margin-right: 1.5rem;
}

.deleteButton {
  cursor: pointer;
}

.form-file-upload {
  height: 16rem;
  max-width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: 1rem;
}

.label-file-upload {
  padding-top: 91px;
}

.label-file-upload-drag-active {
  display: flex;
  align-items: center;
  justify-content: center;
}

.upload-button {
  cursor: pointer;
  padding: 0.25rem;
  font-size: 1rem;
  border: none;
  font-family: "Oswald", sans-serif;
  background-color: transparent;
}

.upload-button:hover {
  text-decoration-line: underline;
}

.drag-file-element {
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 1rem;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
}

.disabled {
  pointer-events: none;
  opacity: 0.3;
}

.deleteBtn {
  font-weight: 500;
  border-radius: 2rem;
  font-style: initial;
  color: $text-color;
  text-align: center;
  padding-top: 0.5rem;
  padding-right: 2.5rem;
  padding-bottom: 0.5rem;
  padding-left: 0.8rem;
  cursor: pointer;
}

.hideMobViewText {
  --tw-text-opacity: 1;
  color: rgba(144, 150, 152, var(--tw-text-opacity));
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.test {
  padding-top: 0.5rem;
  padding-right: 0.8rem;
  padding-bottom: 0.5rem;
  padding-left: 0.8rem;
  margin-right: 0rem;
}

.strongHeader {
  padding-right: 1.1rem;
}

.sizeStyle {
  margin-right: 1.7rem;
}

.mediaSize {
  padding-right: 3.5rem !important;
}

.mediaSizeDevice {
  padding-right: 3.5rem !important;
}

.mediaAction {
  padding-right: 0rem !important;
}
.thumbnail {
  width: 3rem !important;
  height: 3rem !important;
}

.imgName {
  display: flex;
  align-content: space-between;
  align-items: center;
  margin-left: -2rem;
}

.thumbnailImgName {
  flex-shrink: 0;
  word-wrap: break-word;
  max-width: 80%;
  inline-size: 50rem;
}

.tableHeaderSize {
  margin-left: 2.7rem !important;
}

.tableRowColor {
  background: #f2efeb;
}

.uploadMediaNo {
  display: block;
}

@media (min-width: 314px) and (max-width: 912px) {
  .mediaHeader {
    padding: 0rem 1rem;
  }

  .mediaHeaderName {
    flex-basis: 72%;
  }

  .mediaRowContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 2rem;
  }

  .deleteButtonContainer {
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .thumbnail {
    max-width: 3rem !important;
    height: 3rem !important;
  }
  .mediaSize {
    padding-right: 4.5rem !important;
  }

  .thumbnailImgName {
    word-wrap: break-word;
    max-width: 100%;
    inline-size: 10rem;
  }

  .tableHeaderSize {
    margin-left: 2.6rem !important;
  }

  .mediaAction {
    padding-right: 0rem !important;
  }

  .form-file-upload {
    height: auto;
  }
}

@media (min-width: 768px) and (max-width: 819px) {
  .mediaSize {
    padding-right: 2.3rem !important;
  }
  .mediaSizeDevice {
    padding-right: 2.5rem !important;
  }

  .thumbnailImgName {
    word-wrap: break-word;
    max-width: 80%;
    inline-size: 16rem;
  }
  .form-file-upload {
    height: auto;
  }
  .uploadMediaNo {
    display: block;
    margin-left: 95%;
    margin-top: -25px;
  }
}

@media (min-width: 820px) and (max-width: 1180px) {
  .form-file-upload {
    height: auto;
  }
  .uploadMediaNo {
    display: block;
  }
}

@media (max-width: 280px) {
  .mediaHeaderName {
    flex-basis: 50%;
  }

  .mediaRowContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 2rem;
  }

  .deleteButtonContainer {
    margin-left: 0rem;
    margin-right: 0rem;
  }

  .thumbnail {
    /* height: 50%; */
    max-width: 3rem !important;
    height: 3rem !important;
  }
  .mediaSize {
    padding-right: 3.5rem !important;
  }

  .mediaAction {
    padding-right: 0rem !important;
  }
}

@media (min-width: 993px) and (max-width: 1198px) {
  .mediaHeaderName {
    flex-basis: 50%;
  }

  .thumbnailImgName {
    word-wrap: break-word;
    max-width: 80%;
    inline-size: 16rem;
  }
  .form-file-upload {
    height: auto;
  }
}

@media (min-width: 912px) and (max-width: 992px) {
  .mediaHeaderName {
    flex-basis: 30%;
  }
  .thumbnailImgName {
    word-wrap: break-word;
    max-width: 80%;
    inline-size: 16rem;
  }
  .form-file-upload {
    height: auto;
  }
}

@media (min-width: 360px) and (max-width: 414px) {
  .mediaHeaderName {
    flex-basis: 45%;
  }
  .thumbnail {
    max-width: 2rem !important;
    height: 2rem !important;
  }
  .mediaSize {
    padding-right: 0.3rem !important;
  }

  .mediaSizeDevice {
    padding-right: 2.5rem !important;
  }

  .mediaAction {
    padding-right: 0rem !important;
  }
  .thumbnailImgName {
    word-wrap: break-word;
    max-width: 100%;
    inline-size: 8rem;
  }

  .form-file-upload {
    height: auto;
  }
}
