.header-size {
    font-size: 20px !important;
    font-weight: 700 !important;
    padding-top: 1.5rem !important;
}


@media screen and (min-width: 1024px) {
    .header-size {
        font-size: 40px !important;
        font-weight: 700 !important;
        padding-top: 2.5rem !important;
    }
}