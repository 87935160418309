@import "../../assets/css/variables";

.overlayPopUp {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1000;
}

.modalPopup-footerbtn .primary-btn, .modalPopup-footerbtn .secondary-ghost-btn {
    width: 100%;
}

.modalPopUp {
    position: fixed;
    top: 43%;
    left: 49%;
    transform: translate(-50%, -50%);
    background: $white-color;
    padding: 1rem;
    z-index: 9999;
    width: 95vw;
    display: flex;
    .footer-actionbtn .col, .footer-actionbtn .col-md-3 {
        border-top: 0 !important;
    }
}

.modalPopup-footerbtn {
    .bordertop-none {
        border-top: 0 !important;
        padding: 0 !important;
        margin-bottom: 0;
    }
    .cancel-exitBtn {
        display: none;
    }
    .btn-padding {
        margin-top: 1rem;
    }
}

:focus-visible {
    outline: $white-color !important;
}

.autolog-modal {
    .secondary-ghost-btn, .primary-btn {
        width: 100%;
    }
}

.cancel-exitBtn {
    display: none;
}

.modal-para {
    padding-top: 1rem;
}

.disabled {
    pointer-events: none;
    opacity: 0.25;
  }

@media(min-width:314px) and (max-width: 576px) {
    .modalPopCancel {
        width: 100%;
        vertical-align: middle;
        height: auto;
    }
}

@media screen and (min-width: 390px) {
    .modalPopUp {
        top: 30%;
    }
}

@media screen and (min-width: 412px) {
    .modalPopUp {
        top: 28%;
    }
}

@media screen and (min-width: 430px) {
    .modalPopUp {
        top: 26%;
    }
}

@media screen and (min-width: 768px) {

    .exit-save #exitmodal-btn3 {
        margin-bottom: 16px;
    }

    .modalPopUp {
        padding: 1.5rem;
        height: 28.125rem;
        top: 50%;
        width: 78%;
        display: block;
        #exitmodal-div1 {
            width: 100%;
        }
    }
    .cancel-exitBtn {
        display: block;
        .secondary-ghost-btn {
            margin-top: 1rem;
        }
    }
    .modalPopup-footerbtn .cancel-exitBtn {
        display: block;
    }

    .modalPopup-footerbtn .btn-padding {
        margin-top: 0;
    }

    .modalPopup-footerbtn {
        position: absolute;
        bottom: 1.5rem;
        width: 100%;
        left: 0;
        padding: 0 1.5rem;
        .secondary-ghost-btn {
            width: auto;
        }
        .primary-btn {
            width: auto;
            margin-left: 1rem;
        }
    }

    .modal-para {
        padding-top: 1rem;
    }
    
    .modal-footer {
        display: block !important;
        justify-content: space-between !important;
        padding: 0 !important;
        border: 0 !important;
    }
    .cancel-exitBtn {
        display: block;
        .secondary-ghost-btn {
            margin-top: 0;
        }

    }
}

@media screen and (min-width: 800px) {
    .exit-save #exitmodal-btn3 {
        margin-bottom: 0;
    }

    .cancel-exitBtn {
        display: block;
    }
}

@media screen and (min-width: 1023px) {
    .modalPopUp {
        top: 50%;
        width: 50rem;
        display: block;
    }    
}

@media screen and (min-width: 1024px) {
    .modal-para {
        padding-top: 1.5rem;
    }

}
