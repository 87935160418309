.gen_err {
    position: fixed;
    margin-top: 3.5em;
    top: 25%;
    left: 50%;
    /* bring your own prefixes */
    transform: translate(-50%, -50%);
    width: 42%;

}


.gen_font {
    font-family: "Lexend Deca", sans-serif;
    font-size: 30px;
    font-weight: 400;
    line-height: 37.5px;
    text-align: center;

}

.mt-gen-err {
    margin-top: 1rem !important;
}

.mt-24-gen-head {
    margin-top: 2.5rem !important;

}

@media (min-width: 314px) and (max-width: 767px) {
    .gen_err {
        position: fixed;
        top: 30%;
        left: 50%;
        /* bring your own prefixes */
        transform: translate(-50%, -50%);
        width: 88%;

    }

    .gen_font {
        font-family: "Lexend Deca", sans-serif;
        font-size: 23px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;

    }

}

@media (width: 896px) {
    .gen_err {
        margin-top: 2em !important;
        position: fixed;
        top: 30%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 28rem;

    }

    .gen_font {

        font-family: "Lexend Deca", sans-serif;
        font-size: 21px;
        font-weight: 400;
        line-height: 26.5px;
        text-align: center;
    }


    .mt-gen-err {
        margin-top: 0rem !important;
    }

    .mt-24-gen-head {
        margin-top: 0rem !important;

    }


}

@media (min-width: 359px) and (max-width: 376px) {

    .mt-24-gen-head {
        margin-top: -2.5rem !important;

    }

    .gen_font {
        font-family: "Lexend Deca", sans-serif;
        font-size: 20px;
        font-weight: 400;
        line-height: 30px;
        text-align: center;

    }
}

@media (min-width: 767px) and (max-width: 768px) {



    .gen_font {
        font-family: "Lexend Deca", sans-serif;
        font-size: 32px;
        font-weight: 400;
        line-height: 37.5px;
        text-align: center;
        width: 31rem !important;
    }

    .mt-24-gen-head {
        margin-top: 10.5rem !important;

    }

    .gen_err {
        margin-top: 3.5em;
        top: 25%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 63%;

    }


}