@import "../../assets/css/variables";

@media (min-width: 314px) and (max-width: 765px) {
  .balance-buttons .secondary-ghost-btn {
    width: -webkit-fill-available;
    margin-top: 1.5rem !important;
  }

  .balance-buttons .primary-btn {
    display: inline-flex;
    width: -webkit-fill-available;
    height: 44px;
    justify-content: center;
    align-items: center;
    margin-top: 0.75rem !important;
  }
}

.calendar-min-height {
  height: 44px !important;
  padding-left: 0.3rem;
  border: 2px solid $grey-color;
  color: $text-color;
  font-size: 1rem;
  font-weight: 500;
  width: 47%
}

.filter-section {
  padding: 1.875rem;
  .offcanvas-body {
    padding: 0;
    width: 100%;
  }
  .sorting-dd {
    width: 100%;
  }
  .primary-btn {
    width: 11.125rem;
  }
  .offcanvas-header {
    padding: 0;
    display: flow;
    span {
      float: right;
    }
  }
}

.sorting-dd {
  width: 82.6%;
  height: 44px;
  border: 2px solid $grey-color;
  padding: 8px 12px;
  font-size: 16px;
  font-weight: 500;
  background: url(../../assets/img/arrow_downward_alt.png) no-repeat right;
  background-position-x: 97%;
  appearance: none;
}

@media screen and (min-width: 412px) {
  .sorting-dd {
    width: 85%;
  }
}

@media screen and (min-width: 430px) {
  .sorting-dd {
    width: 86%;
  }
}

@media screen and (min-width: 768px) {
  .sorting-dd {
    width: 92%;
  }
}

@media screen and (min-width: 1023px) {
  .sorting-dd {
    width: 100%;
  }
}

.filter-section .primary-btn {
  width: 11.125rem;
}

.filter-section .offcanvas-header {
  padding: 0;
  display: flow;
}

#mySidenav .closebtn {
  display: none !important;
}

@media (min-width: 768px) {
  #main_div_tx .col-md-4 {
    flex: 0 0 auto;
    width: 30%;
  }

  #main_div_tx #searchLabel .search-input {
    width: 18.25rem !important;
    height: 2.75rem !important;
  }

  #main_div_tx .filter-dd {
    width: 8.875rem !important;
    height: 2.75rem !important;
  }

  #main_div_tx .filter-tx {
    text-align: right !important;
  }
}
