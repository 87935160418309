#velQues-div1 {
    padding-top: 1.875rem;
    .PrivateSwitchBase-root-1 {
        padding-top: 0;
    }
    .MuiTypography-body1 {
        font-weight: 300;
    }
    .MuiFormControlLabel-label {
        padding-bottom: 0;
    }
}

@media screen and (min-width: 1024px) {
    #velQues-div1 {
        padding-top: 1.875rem;
        .PrivateSwitchBase-root-1 {
            padding-top: 1rem;
        }
        .MuiFormControlLabel-root {
            padding-top: 0;
        }
        .MuiFormControlLabel-label {
            padding-bottom: 0;
        }
    }
}